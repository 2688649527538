// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Scalars = require("../../../scalars/Scalars.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ReadMoreButton = require("../../../components/buttons/ReadMoreButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var NotificationsUtils = require("../../../v5/common/notifications/NotificationsUtils.bs.js");
var FragmentErrorBoundary = require("../../../v5/common/ErrorHandling/FragmentErrorBoundary.bs.js");
var NotificationWidgetItem = require("./NotificationWidgetItem.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var NotificationWidgetRelay_query_graphql = require("../../../__generated__/NotificationWidgetRelay_query_graphql.bs.js");
var NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql = require("../../../__generated__/NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.bs.js");

var convertFragment = NotificationWidgetRelay_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetRelay_query_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetRelay_query_graphql.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertVariables = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertVariables;

var convertResponse = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use$1(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.node, convertResponse, convertWrapRawResponse, param);
}

var MarkNotificationReadByCurrentUserMutation_notificationStatus_decode = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Utils.notificationStatus_decode;

var MarkNotificationReadByCurrentUserMutation_notificationStatus_fromString = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Utils.notificationStatus_fromString;

var MarkNotificationReadByCurrentUserMutation = {
  notificationStatus_decode: MarkNotificationReadByCurrentUserMutation_notificationStatus_decode,
  notificationStatus_fromString: MarkNotificationReadByCurrentUserMutation_notificationStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$1
};

function NotificationWidgetRelay$WithoutErrorBoundary(props) {
  var query = RescriptRelay_Fragment.useFragment(NotificationWidgetRelay_query_graphql.node, convertFragment, props.query);
  var isImpersonated = CurrentUser.useIsImpersonated();
  var match = use$1();
  var markNotificationAsread = match[0];
  var markAsRead = function (id) {
    if (isImpersonated) {
      return ;
    } else {
      Curry._8(markNotificationAsread, {
            input: {
              id: id
            }
          }, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      return ;
    }
  };
  var notifications = Core__Array.filterMap(Core__Option.getOr(Core__Option.flatMap(query.notificationsForCurrentUserV5, (function (v) {
                  return v.edges;
                })), []), (function (v) {
          if (v !== undefined) {
            return v.node;
          }
          
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: NotificationsUtils.NotificationGrouper.groupNotifications(notifications, (function (v) {
                                return Curry._1(Scalars.DateTime.toUTC, v.createdAt);
                              })).map(function (x) {
                            var groupTime = NotificationsUtils.NotificationGrouper.toString(x[0]);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("h3", {
                                                children: groupTime,
                                                className: "text-2xl font-medium text-gray-800"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: x[1].map(function (v) {
                                                      return JsxRuntime.jsx(FragmentErrorBoundary.make, {
                                                                  fragmentDisplayName: "notification widget item",
                                                                  children: JsxRuntime.jsx(NotificationWidgetItem.make, {
                                                                        notification: v.fragmentRefs,
                                                                        markAsRead: markAsRead
                                                                      })
                                                                }, v.nodeId);
                                                    }),
                                                className: "mt-4"
                                              })
                                        ]
                                      }, groupTime);
                          }),
                      className: "mb-8 flex flex-col space-y-12"
                    }),
                props.showMore && notifications.length > 0 ? JsxRuntime.jsx(ReadMoreButton.make, {
                        href: "/notifications",
                        children: "View All"
                      }) : null
              ]
            });
}

var WithoutErrorBoundary = {
  make: NotificationWidgetRelay$WithoutErrorBoundary
};

function NotificationWidgetRelay(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "notifications",
              children: JsxRuntime.jsx(NotificationWidgetRelay$WithoutErrorBoundary, {
                    query: props.query,
                    showMore: props.showMore
                  })
            });
}

var NotificationGrouper;

var make = NotificationWidgetRelay;

exports.NotificationGrouper = NotificationGrouper;
exports.QueryFragment = QueryFragment;
exports.MarkNotificationReadByCurrentUserMutation = MarkNotificationReadByCurrentUserMutation;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Scalars Not a pure module */
